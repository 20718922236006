import { VbServiceFactory } from '@/services/VbServiceFactory'
import { ServiceFactory } from '@/services/ServiceFactory'
// const _projectService = VbServiceFactory.get('ProjectService')
const _billingService = VbServiceFactory.get('BillingService')
const _barsService = ServiceFactory.get('BarsService')

export default {
  name: 'VbProjectInfo',
  data () {
    return {
      bars: [],
      tab: null,
      menu: false,
      menu2: false,
      projectTypeItems: ['Proyecto interno', 'Proyecto externo'],
      paymentFrequencyItems: ['Mensual'],
      contractPeriodItems: [1, 2, 3, 4, 5, 6],
      paymentTypeItems: ['Cuotas', 'Al contado'],
      platformTypeItems: ['TikTok'],
      billing: [],
      project: {},
      loadingQR: false
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        console.log(this.$route.params.id)
        try {
          const response = await _billingService.show(this.$route.params.id)
          console.log(response)
          this.billing = response.billings
          this.project = response.project
          const barsResponse = await _barsService.listBar()
          console.log(barsResponse)
          this.bars = barsResponse.bars
        } catch (error) {
          console.log(error)
        }
      } else {
        this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
      }
    },
    goBack () {
      // this.$router.push({ name: 'billing' }).catch(() => {})
      this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
    },
    editProject () {
      this.$router.push({ name: 'edit-project', params: { id: this.$route.params.id } }).catch(() => {})
    },
    async changeState (id) {
      console.log(id)
      try {
        const resp = await _billingService.editState(id)
        console.log(resp)
        this.$notify({
          type: 'success',
          text: 'Estado modificado correctamente'
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        this.initialize()
      }
    },
    async generateQr (id) {
      try {
        this.loadingQR = true
        // console.log(this.postContract)
        const response = await _billingService.generateQr(id)
        console.log(response)
        this.$swal(
          'QR generado correctamente',
          '',
          'success'
        ).then(async (result) => {
          // this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
          this.loadingQR = false
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        this.loadingQR = false
      }
    }
  }
}
